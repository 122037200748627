export default {
  space: [
    0,
    2,
    4,
    8,
    16,
    32,
    64,
    128,
    256,
    512
  ],
  fonts: {
    body: "Roboto, sans-serif",
    heading:  "'Futura', sans-serif",
    monospace: 'Menlo, monospace'
  },
  fontSizes: [
    12,
    14,
    16,
    20,
    24,
    32,
    48,
    64,
    96
  ],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 800,
    light: 300,
  },
  lineHeights: {
    body: 1.5,
    heading: 1
  },
  sizes: {
    default: "90vw",
    xsmall: "320px",
    small: "640px",
    medium: "768px",
    large: "1024px",
    max: "1280px",
  },
  layout: {
    container: {
      width: '100%',
      maxWidth: 'max',
      mx: 'auto'
    },
    medium: {
      width: '100%',
      maxWidth: 'medium',
      mx: 'auto'
    }
  },
  colors: {
    text: '#232321',
    background: '#fff',
    primary: '#03a9f4',
    secondary: '#212121',
    muted: '#3b3633',
    dark: '#232321',
    light: '#F6F6F4',
    gray: '#bab9ad',
    blue: '#98D2EB',
  },
  shadows: {
    small: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
    regular: "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
    medium: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
    large: "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)"
  },
  radii: {
    small: "1px",
    medium: "3px",
    large: "5px",
    rounded: "999px",
  },
  buttons: {
    primary: {
      px: 4,
      py: 4,
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      textTransform: 'uppercase',
      color: 'white',
      borderRadius: 'small',
      bg: 'primary',
      cursor: 'pointer',
      '&:hover': {
        bg: 'dark',
      },
    },
    secondary: {
      px: 4,
      py: 4,
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      textTransform: 'uppercase',
      color: 'white',
      borderRadius: 'small',
      bg: 'secondary',
      cursor: 'pointer',
      '&:hover': {
        bg: 'dark',
      },
    },
    dark: {
      px: 4,
      py: 4,

      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      textTransform: 'uppercase',
      color: 'white',
      borderRadius: 'small',
      bg: 'dark',
      cursor: 'pointer',
      '&:hover': {
        bg: 'dark',
      },
    },
    light: {
      px: 4,
      py: 4,

      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      textTransform: 'uppercase',
      color: 'dark',
      borderRadius: 'small',
      bg: 'light',
      cursor: 'pointer',
    },

  },
  forms: {
    label: {
      fontSize: 1,
      fontWeight: 'bold',
    },
    input: {
      borderColor: '#e0e0e0',
      borderRadius: "small",
      my: 2,
      p: 4,
      '&:focus': {
        borderColor: 'primary',
        boxShadow: 'medium',
        outline: 'none',
      },
    },
    select: {
      borderColor: 'dark',
      my: 2,
      p: 4,
      '&:focus': {
        borderColor: 'primary',
        boxShadow: 'medium',
        outline: 'none',
      },
    },
    textarea: {
      borderColor: 'dark',
      '&:focus': {
        borderColor: 'primary',
        boxShadow: 'medium',
        outline: 'none',
      },
    },
    slider: {
      bg: 'muted',
    },
  },
  styles: {
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body'
    },
    h1: {
      color: 'dark',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      textTransform: 'uppercase',
      fontSize: [4, 4, 5, 5],
      light: {
        color: 'light'
      }
    },
    h2: {
      color: 'dark',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      textTransform: 'uppercase',
      fontSize: [3,3,4,4],
      light: {
        color: 'light'
      }
    },
    h3: {
      color: 'dark',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: [1,1,2,2],
      textTransform: "uppercase",
      light: {
        color: 'light'
      }
    },
    h4: {
      color: 'dark',
      fontFamily: 'body',
      lineHeight: 'heading',
      fontWeight: 'light',
      fontSize: 2,
      light: {
        color: 'light'
      }
    },
    h5: {
      color: 'dark',
      fontFamily: 'body',
      lineHeight: 'heading',
      fontWeight: 'light',
      fontSize: 1,
      light: {
        color: 'light'
      }
    },
    h6: {
      color: 'dark',
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'light',
      fontSize: 0,
      light: {
        color: 'light'
      }
    },
    p: {
      color: 'text',
      fontFamily: 'body',
      fontWeight: 'light',
      lineHeight: 'body',
      fontSize: 2,
      light: {
        color: 'light'
      }
    },
    a: {
      color: 'dark',
      fontFamily: 'body',
      fontWeight: 'heading',
      lineHeight: 'body',
      fontSize: 0,
      textTransform: 'uppercase',
      textDecoration: 'none',
      cursor: 'pointer'
    },
    pre: {
      fontFamily: 'monospace',
      overflowX: 'auto',
      code: {
        color: 'inherit'
      }
    },
    code: {
      fontFamily: 'monospace',
      fontSize: 'inherit'
    },
    img: {
      maxWidth: '100%'
    }
  }
}